<template>
  <div class="mb-32 md:mb-56">
    <!-- Head with banner -->
    <HeroBanner
      v-if="hasHeroBanner"
      data-test="article-heroBanner"
      :image="content?.banner?.image"
      :text-color="content?.banner?.textColor"
    >
      <Heading
        :badge="content?.badge"
        data-test="article-heading"
        :text-color="content?.banner?.textColor"
        :title="content?.title"
      />

      <PublicationUpdateDate
        class="mt-0 md:mt-12"
        :text-color="content?.banner?.textColor"
        :update-date="content?.updateDate"
      />
    </HeroBanner>

    <!-- Head when no banner -->
    <Container
      v-if="!hasHeroBanner"
      class="bg-static-default-mid mood-inverse flex min-h-[192px] flex-col justify-end py-24 md:py-40"
      data-test="article-default-banner"
    >
      <Heading
        :badge="content?.badge"
        data-test="article-heading"
        :title="content?.title"
      />

      <PublicationUpdateDate
        class="mt-0 md:mt-12"
        :text-color="content?.banner?.textColor"
        :update-date="content?.updateDate"
      />
    </Container>

    <Breadcrumb
      :current-page-title="breadcrumb.currentPageTitle"
      data-test="article-breadcrumb"
      :intermediary-path="breadcrumb.intermediaryPath"
      @click-breadcrumb="(title: string) => trackBreadcrumbClicked(title)"
    />

    <div class="min-h-[100vh]">
      <ArticleEditorial :summary="content?.summary" />

      <ArticleBlockContainer
        v-for="({ id, props, hiddenDevices, type }, index) in content?.blocks"
        :key="id"
        :block-type="type"
      >
        <BlockRenderer
          :id="id"
          :block-position="index + 1"
          :data-test="id"
          :hidden-devices="hiddenDevices"
          :props="props"
          :type="type"
        />
      </ArticleBlockContainer>

      <AuthorCard :author :votes />

      <Container v-if="relatedArticles.length > 0">
        <div class="my-16 pt-56" data-test="related-articles">
          <ArticleCardsCollection
            :articles="relatedArticles"
            layout="carousel"
            :title="relatedArticlesTitle"
          />
        </div>
      </Container>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRoute, useRuntimeConfig } from '#imports'
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import HeroBanner from '../../cms/blocks/HeroBanner/HeroBanner.vue'
import { valueBuilder } from '../../cms/helpers/tracking/tracking'
import BlockRenderer from '../../cms/shared-components/BlockRenderer/BlockRenderer.vue'
import Breadcrumb from '../../cms/shared-components/Breadcrumb/Breadcrumb.vue'
import Container from '../../cms/shared-components/Container.vue'
import Heading from '../../cms/shared-components/Heading/Heading.vue'
import ArticleCardsCollection from '../components/ArticleCardsCollection.vue'

import translations from './Article.translations'
import ArticleBlockContainer from './components/ArticleBlockContainer/ArticleBlockContainer.vue'
import ArticleEditorial from './components/ArticleEditorial.vue'
import AuthorCard from './components/AuthorCard.vue'
import PublicationUpdateDate from './components/PublicationUpdateDate/PublicationUpdateDate.vue'
import { useArticleHead } from './composables/useArticleHead'
import { useFetchArticle } from './composables/useFetchArticle'
import { useGetBreadcrumb } from './composables/useGetBreadcrumb'
import { useGetRelatedArticles } from './composables/useGetRelatedArticles'
import { useHandleArticleFetchErrors } from './composables/useHandleFetchErrors'
import { useHandleIncorrectRouteParams } from './composables/useHandleIncorrectRouteParams'

const route = useRoute()
const tracking = useTracking()
const i18n = useI18n()
const { FF_ENABLE_CONTENT_SERVICE_ON_ARTICLES } = useRuntimeConfig().public

const { fetchedContent, fetchedVotes } = await useFetchArticle(
  FF_ENABLE_CONTENT_SERVICE_ON_ARTICLES,
)
const { data: content, error: contentErrorRef } = fetchedContent
const { data: votes, error: votesErrorRef } = fetchedVotes
useHandleArticleFetchErrors({ contentErrorRef, votesErrorRef })
useHandleIncorrectRouteParams(content)
useArticleHead(content)

const author = computed(() => content.value?.author?.props)
const relatedArticles = useGetRelatedArticles(content)

const breadcrumb = useGetBreadcrumb(content)

const hasHeroBanner = computed(() => {
  return !isEmpty(content.value?.banner)
})

// TODO COM-1345 Keep translation only
const relatedArticlesTitle = computed(
  () => content.value?.relatedArticles?.props.title ?? i18n(translations.title),
)

function trackBreadcrumbClicked(breadcrumbTitle: string) {
  const { name, params } = route
  const zone = valueBuilder([<string>name, params.pageName])

  tracking.trackClick({
    name: breadcrumbTitle,
    value: 'Breadcrumb',
    zone,
  })
}
</script>
