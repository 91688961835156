import { type MaybeRefOrGetter, computed, toValue } from 'vue'

import type { ArticlePageContent } from '@backmarket/http-api/src/api-specs-content'
import type { ArticleCardsList } from '@backmarket/http-api/src/api-specs-content/models/article-cards-list'
import type { ArticleCard } from '@backmarket/nuxt-layer-cms/ArticleCard/ArticleCard.types'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

// TODO COM-1345 Remove the mapping
function mapToArticles(props: ArticleCardsList = {}) {
  if ('articles' in props && props.articles) return props.articles
  if ('guides' in props && props.guides) return props.guides

  return []
}

export function useGetRelatedArticles(
  contentRef: MaybeRefOrGetter<ArticlePageContent | null>,
) {
  return computed(() => {
    const content = toValue(contentRef)

    if (
      isEmpty(content?.relatedArticles) ||
      isEmpty(content.relatedArticles.props)
    ) {
      return []
    }

    const relatedArticles = mapToArticles(content?.relatedArticles?.props)

    return relatedArticles.map<ArticleCard>((article) => {
      const { id, image, link, text, title, translatedCategory } = article

      return {
        id: id.toString(),
        category: translatedCategory,
        imageAlt: image.alt,
        imageSrc: image.src,
        link,
        text,
        title,
      }
    })
  })
}
